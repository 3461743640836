import React, {Component} from 'react';
import './Styling/animation.css';
import NavBar from './navbar';
export default class AnimationParallex extends Component {
  componentDidMount() {
    window.addEventListener(
      'scroll',
      () => {
        let parent = document.getElementById('parallax-container');
        let children = parent.getElementsByTagName('div');
        for (let i = 0; i < children.length; i++) {
          children[i].style.transform =
            'translateY(-' + (window.pageYOffset * i) / children.length + 'px)';
        }
      },
      false,
    );
  }
  render() {
    return (
      <div>
        <div id="parallax-container">
          <div className="Div0"></div>
          <div className="Div1"></div>
          <div className="Div2"></div>
          <div className="Div3"></div>
          <div className="Div4"></div>
          <div className="Div5"></div>
          <div className="Div6"></div>

          <div className="Div7"></div>
          <div className="Div10"></div>
          <div className="Div8"></div>
          <div className="Div9"></div>
        </div>
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#210002',
            position: 'relative',
          }}></div>
      </div>
    );
  }
}
