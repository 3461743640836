import Mylogo from './mylogo';

import React, {Component} from 'react';

import NavBar from './navbar';
import './Styling/homepage.css';
import './Styling/wireframe.css';
import MyImage from '../images/laptop.png';
import MobileApp from '../images/mobileapp.jpg';

import ReactLogo from '../images/logo.svg';
import MongologoDb from '../images/mongod.png';
import ReduxLogo from '../images/reduxlogo.png';
import NodejaSkills from '../images/nodejs-2.png';
import ExpressSkills from '../images/express.png';
import ParrallexAnimation from './animationParallex';

class Homepage extends Component {
  render() {
    return (
      <div className="HomeMainContainer">
        <div className="HomepageContainer">
          <ParrallexAnimation />
          <div style={{position: 'absolute', top: 0}}>
            <NavBar />
          </div>
        </div>
        <div className="RightLogo"></div>
        <div className="SocialSite">
          <div>
            <a
              href="https://github.com/Ritzrawal"
              alt=""
              className=""
              title="Join us on Facebook">
              <i className="fa fa-github" aria-hidden="true"></i>
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/ritesh.rawal.94"
              alt=""
              className=""
              title="Join us on Facebook">
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
          </div>
          <div>
            <a
              href="https://mail.google.com"
              alt=""
              className=""
              title="Join us on Google">
              <i className="fa fa-google" aria-hidden="true"></i>
            </a>
          </div>
          <div>
            <a
              href="https://twitter.com/rtz_rawal"
              alt=""
              className=""
              title="Join us on Twitter">
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/in/ritesh-rawal-a18342105"
              alt=""
              className=""
              title="Join us on Linkedin">
              <i className="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/ritz_rawal"
              alt=""
              className=""
              title="Join us on Instagram">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </div>
        </div>

        <div className="HomepageloweContainer">
          <div className="docs-demo-device ios">
            <figure>
              <svg className="docs-demo-device__ios-notch" viewBox="0 0 219 31">
                {' '}
                <path
                  d="M0 1V0h219v1a5 5 0 0 0-5 5v3c0 12.15-9.85 22-22 22H27C14.85 31 5 21.15 5 9V6a5 5 0 0 0-5-5z"
                  fill-rule="evenodd">
                  {' '}
                </path>
              </svg>
              <div>
                <img className="Image" src={MobileApp} alt=""></img>
              </div>
            </figure>
          </div>
          <div className="ProjectLeft">
            <img className="Image" src={MyImage} alt=""></img>
            <div className="ReducLogoImage">
              <img className="Reduxlogosize" src={ReduxLogo} alt="logo"></img>
            </div>
            <div className="NewSpin">
              <img className="ReactlogoCustom" src={ReactLogo} alt="logo"></img>
            </div>
            <div className="NodejsLogo">
              <img
                className="Nodejslogosize"
                src={NodejaSkills}
                alt="logo"></img>

              <div className="ExpressjsLogo">
                <img
                  className="EXpresslogosize"
                  src={ExpressSkills}
                  alt="logo"></img>
              </div>
            </div>

            <div className="MongodbLogo">
              <img
                className="MonogoLogodesign"
                src={MongologoDb}
                alt="logo"></img>
            </div>

            <div className="SkillAnim">Skills </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage;
