import React, {Component} from 'react';
import './Styling/contact.css';
import NavBar from './navbar';
import MYLogo from './mylogo.js';
import ProfilePic from '../images/profile.jpg';
class ContactPage extends Component {
  render() {
    return (
      <div className="ContactHomepage">
        <div>
          <NavBar />
        </div>
        <div className="ContactMainContainer">
          <div>
            <MYLogo />
          </div>
          <div className="ContactLeftHalf">
            <div className="Detailme">
              <div className="ProfilePicture">
                <img src={ProfilePic} alt="" className="image--cover"></img>
              </div>
            </div>
          </div>
          <div className="ContactRightHalf">
            <div className="ContactMe">
              <div>
                <text className="ContactHeading">CONTACT ME</text>
              </div>
              <div className="EmailDisplay">
                <text className="InputText"> riteshrawal31@gmail.com:</text>
              </div>
              <div className="EmailDisplay">
                <text className="InputText"> +1 3658831061:</text>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPage;
