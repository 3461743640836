import React,{Component} from 'react';
import './App.css';
import Main from './component/router'
import AnimationParallex from './component/animationParallex'
// import Projectsdiv from './'
export default class App extends Component {
  render(){
    return(
      <div><Main/></div>
    )

  }
  
 
}

