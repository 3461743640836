import React, {Component} from 'react';
import './Styling/middlelogo.css';
export default class Middlelogo extends Component {
  render() {
    return (
      <div>
        <div classNameName="container">
          <svg classNameName="money-sm money" id="Layer_1">
            <title id="moneytitle" lang="en">
              Monetary Illustration
            </title>
            <g className="one" fill="#23262C">
              <path d="M7.9,10.1H5.7V9.4h2.2c0.2,0,0.3-0.1,0.3-0.3S8.1,8.8,7.9,8.8H7V8.5c0-0.2-0.1-0.3-0.3-0.3 c-0.2,0-0.3,0.1-0.3,0.3v0.3H5.4C5.2,8.8,5.1,9,5.1,9.1v1.2c0,0.2,0.1,0.3,0.3,0.3h2.2v0.6H5.4c-0.2,0-0.3,0.1-0.3,0.3 c0,0.2,0.1,0.3,0.3,0.3h0.9v0.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-0.3h0.9c0.2,0,0.3-0.1,0.3-0.3v-1.2 C8.2,10.2,8.1,10.1,7.9,10.1z" />
              <path d="M10.5,8.3c-0.4-0.7-1-1.4-1.5-1.9C8.7,6,8.4,5.7,8.3,5.4C8.5,5.3,8.6,5.1,8.7,5c0.1-0.1,0.1-0.3,0-0.4 c-0.1-0.1-0.3-0.1-0.4,0c0.1-0.4,0.4-0.9,1-1.4c0.1-0.1,0.2-0.3,0.1-0.4C9.4,2.8,9,2,8.2,2C7.7,2,7.5,2.3,7.5,2.5 C7.4,2.6,7.4,2.6,7.3,2.6C7,2.6,6.8,2.5,6.5,2.3C6.1,2.2,5.8,2,5.4,2h0C4.7,2,4,2.6,4,2.7C3.9,2.8,3.9,2.9,3.9,3 C3.9,3,3.9,3.1,4,3.2C4.4,3.5,4.8,4,5,4.5c0,0,0,0,0,0c-0.1-0.1-0.3-0.1-0.4,0C4.5,4.7,4.5,4.9,4.6,5C4.7,5.1,4.8,5.3,5,5.4 c-0.1,0.3-0.5,0.6-0.8,1c-0.5,0.5-1,1.1-1.5,1.9C2.3,9.2,2,10.1,2,11c0,1.3,0.5,2.2,1.4,2.7c0.8,0.5,1.8,0.7,3.3,0.7 s2.5-0.2,3.3-0.7c0.9-0.6,1.4-1.5,1.4-2.7C11.3,10.1,11,9.2,10.5,8.3L10.5,8.3z M4.7,2.9c0.2-0.2,0.5-0.3,0.8-0.3h0 c0.2,0,0.5,0.1,0.8,0.3c0.3,0.2,0.7,0.3,1.1,0.3c0.5,0,0.7-0.3,0.7-0.5c0.1-0.1,0.1-0.1,0.2-0.1c0.2,0,0.4,0.1,0.5,0.3 C7.9,3.6,7.6,4.6,7.6,5c-0.2,0.1-0.5,0.1-1,0.1s-0.7,0-1-0.1C5.6,4.6,5.5,3.7,4.7,2.9L4.7,2.9z M6.6,13.8c-2.8,0-4-0.8-4-2.8 c0-1.9,1.2-3.2,2.1-4.2C5.1,6.4,5.5,6,5.6,5.6c0.3,0.1,0.6,0.1,1,0.1s0.8,0,1-0.1C7.8,6,8.2,6.4,8.6,6.8c0.9,1,2.1,2.3,2.1,4.2 C10.7,13,9.5,13.8,6.6,13.8L6.6,13.8z" />
            </g>
            <g className="two" fill="#23262C">
              <path d="M21.2,10.1h-2.2V9.4h2.2c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3h-0.9V8.5c0-0.2-0.1-0.3-0.3-0.3 c-0.2,0-0.3,0.1-0.3,0.3v0.3h-0.9c-0.2,0-0.3,0.1-0.3,0.3v1.2c0,0.2,0.1,0.3,0.3,0.3h2.2v0.6h-2.2c-0.2,0-0.3,0.1-0.3,0.3 c0,0.2,0.1,0.3,0.3,0.3h0.9v0.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-0.3h0.9c0.2,0,0.3-0.1,0.3-0.3v-1.2 C21.5,10.2,21.4,10.1,21.2,10.1z" />
              <path d="M23.9,8.3c-0.4-0.7-1-1.4-1.5-1.9c-0.3-0.4-0.7-0.7-0.8-1c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.1-0.3,0-0.4 c-0.1-0.1-0.3-0.1-0.4,0c0.1-0.4,0.4-0.9,1-1.4c0.1-0.1,0.2-0.3,0.1-0.4c0,0-0.4-0.8-1.2-0.8c-0.5,0-0.7,0.3-0.7,0.5 c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5-0.1-0.8-0.3C19.5,2.2,19.1,2,18.8,2h0c-0.7,0-1.4,0.6-1.5,0.7c-0.1,0.1-0.1,0.2-0.1,0.2 s0.1,0.2,0.1,0.2c0.5,0.3,0.8,0.8,1,1.3c0,0,0,0,0,0c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.3,0.3,0.5,0.4 c-0.1,0.3-0.5,0.6-0.8,1c-0.5,0.5-1,1.1-1.5,1.9c-0.5,0.9-0.8,1.8-0.8,2.7c0,1.3,0.5,2.2,1.4,2.7c0.8,0.5,1.8,0.7,3.3,0.7 c1.5,0,2.5-0.2,3.3-0.7c0.9-0.6,1.4-1.5,1.4-2.7C24.6,10.1,24.4,9.2,23.9,8.3L23.9,8.3z M18,2.9c0.2-0.2,0.5-0.3,0.8-0.3h0 c0.2,0,0.5,0.1,0.8,0.3c0.3,0.2,0.7,0.3,1.1,0.3c0.5,0,0.7-0.3,0.7-0.5c0.1-0.1,0.1-0.1,0.2-0.1c0.2,0,0.4,0.1,0.5,0.3 C21.2,3.6,21,4.6,20.9,5c-0.2,0.1-0.5,0.1-1,0.1c-0.4,0-0.7,0-1-0.1C19,4.6,18.8,3.7,18,2.9L18,2.9z M20,13.8c-2.8,0-4-0.8-4-2.8 c0-1.9,1.2-3.2,2.1-4.2C18.5,6.4,18.8,6,19,5.6c0.3,0.1,0.6,0.1,1,0.1c0.4,0,0.8,0,1-0.1c0.2,0.4,0.5,0.7,0.9,1.2 c0.9,1,2.1,2.3,2.1,4.2C24,13,22.8,13.8,20,13.8L20,13.8z" />
            </g>
            <g className="three" fill="#23262C">
              <path d="M34.7,10.1h-2.2V9.4h2.2c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3h-0.9V8.5c0-0.2-0.1-0.3-0.3-0.3 s-0.3,0.1-0.3,0.3v0.3h-0.9c-0.2,0-0.3,0.1-0.3,0.3v1.2c0,0.2,0.1,0.3,0.3,0.3h2.2v0.6h-2.2c-0.2,0-0.3,0.1-0.3,0.3 s0.1,0.3,0.3,0.3h0.9v0.3c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3v-0.3h0.9c0.2,0,0.3-0.1,0.3-0.3v-1.2C35,10.2,34.9,10.1,34.7,10.1 z" />
              <path d="M37.3,8.3c-0.4-0.7-1-1.4-1.5-1.9c-0.3-0.4-0.7-0.7-0.8-1c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.1-0.3,0-0.4 c-0.1-0.1-0.3-0.1-0.4,0c0.1-0.4,0.4-0.9,1-1.4c0.1-0.1,0.2-0.3,0.1-0.4c0,0-0.4-0.8-1.2-0.8c-0.5,0-0.7,0.3-0.7,0.5 c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5-0.1-0.8-0.3C33,2.2,32.6,2,32.3,2h0c-0.7,0-1.4,0.6-1.5,0.7c-0.1,0.1-0.1,0.2-0.1,0.2 c0,0.1,0.1,0.2,0.1,0.2c0.5,0.3,0.8,0.8,1,1.3c0,0,0,0,0,0c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.3,0.3,0.5,0.4 c-0.1,0.3-0.5,0.6-0.8,1c-0.5,0.5-1,1.1-1.5,1.9c-0.5,0.9-0.8,1.8-0.8,2.7c0,1.3,0.5,2.2,1.4,2.7c0.8,0.5,1.8,0.7,3.3,0.7 c1.5,0,2.5-0.2,3.3-0.7c0.9-0.6,1.4-1.5,1.4-2.7C38.1,10.1,37.9,9.2,37.3,8.3L37.3,8.3z M31.5,2.9c0.2-0.2,0.5-0.3,0.8-0.3h0 c0.2,0,0.5,0.1,0.8,0.3c0.3,0.2,0.7,0.3,1.1,0.3c0.5,0,0.7-0.3,0.7-0.5c0.1-0.1,0.1-0.1,0.2-0.1c0.2,0,0.4,0.1,0.5,0.3 c-0.9,0.8-1.1,1.7-1.1,2.1c-0.2,0.1-0.5,0.1-1,0.1c-0.4,0-0.7,0-1-0.1C32.5,4.6,32.3,3.7,31.5,2.9L31.5,2.9z M33.5,13.8 c-2.8,0-4-0.8-4-2.8c0-1.9,1.2-3.2,2.1-4.2C32,6.4,32.3,6,32.5,5.6c0.3,0.1,0.6,0.1,1,0.1c0.4,0,0.8,0,1-0.1 C34.7,6,35,6.4,35.4,6.8c0.9,1,2.1,2.3,2.1,4.2C37.5,13,36.3,13.8,33.5,13.8L33.5,13.8z" />
            </g>
            <g fill="#23262C">
              <path d="M3.3,23.7v-4.6h1.6l0.8,3.4l0.8-3.4h1.6v4.6H7.2v-3.6H7.1l-0.9,3.4H5.2l-0.9-3.4H4.2v3.6H3.3z" />
              <path d="M18.1,19.1h1.8c0.5,0,0.8,0.1,1.1,0.3c0.2,0.2,0.4,0.5,0.4,0.9c0,0.3,0,0.5-0.1,0.6 c-0.1,0.2-0.2,0.3-0.4,0.4c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0.1,0.7c0,0.4-0.1,0.8-0.4,1c-0.3,0.2-0.6,0.3-1.1,0.3h-1.8 V19.1z M19.9,19.9H19V21h0.8c0.4,0,0.5-0.2,0.5-0.5S20.2,19.9,19.9,19.9z M19.9,21.7H19v1.1h0.9c0.2,0,0.4,0,0.5-0.1 c0.1-0.1,0.1-0.2,0.1-0.4C20.5,21.9,20.3,21.7,19.9,21.7z" />
              <path d="M32.3,19.9v-0.8h3.4v0.8h-1.2v3.8h-0.9v-3.8H32.3z" />
            </g>
          </svg>
          <svg aria-labelledby="title" id="svg" viewBox="0 0 765 587">
            <svg
              version="1.1"
              id="svg"
              width="495.5px"
              height="600px"
              viewBox="-15 -89.7 495.5 600">
              <title id="title" lang="en">
                Circle of icons that illustrate Global Warming Solutions
              </title>
              <g className="iconCircle">
                <g id="circle">
                  <path
                    id="backCircle"
                    className="st0"
                    d="M53.4,154.3c0-93.1,75.5-168.6,168.6-168.6s168.6,75.5,168.6,168.6S315.1,322.9,222,322.9
      S53.4,247.4,53.4,154.3z"
                  />
                  <path
                    className="st1"
                    d="M222.1,115.8c-21.2,0-38.4,17.2-38.4,38.4s17.2,38.4,38.4,38.4s38.4-17.2,38.4-38.4S243.3,115.8,222.1,115.8
      L222.1,115.8z"
                  />
                  <circle className="st2" cx="222.4" cy="153.8" r="191.4" />
                  <circle className="st3" cx="222.1" cy="154.3" r="79.2" />
                  <path
                    className="st4"
                    d="M222.1,129.1c-13.9,0-25.2,11.3-25.2,25.2c0,13.9,11.3,25.2,25.2,25.2s25.2-11.3,25.2-25.2
      S236,129.1,222.1,129.1z"
                  />
                  <path
                    className="st5"
                    d="M189.8,7.1v3.6c41.8-6.8,82.4,3,114.3,24.6c32.3,21.8,55.7,55.4,62.6,95.9s-3.9,80-27.1,111.3
      c-22.9,30.9-57.9,53.7-99.6,61.2l0.6,3.6c85.8-15.5,143.9-94.3,129.8-176.7C356.3,48.1,275.9-7,189.8,7.1z"
                  />
                  <path
                    className="st6"
                    d="M318,154.2c0-53-43-95.9-95.9-95.9c-53,0-95.9,43-95.9,95.9c0,53,43,95.9,95.9,95.9
      C275.1,250.1,318,207.2,318,154.2z M222.1,243.6c-24.7,0-47-10-63.2-26.2s-26.2-38.5-26.2-63.2s10-47,26.2-63.2
      s38.5-26.2,63.2-26.2s47,10,63.2,26.2c16.2,16.2,26.2,38.5,26.2,63.2s-10,47-26.2,63.2S246.8,243.6,222.1,243.6L222.1,243.6z"
                  />
                  <path
                    className="st7"
                    d="M248.7,50.1l-0.6,2.5c40,9,72.4,41.7,78.9,84.7c2.9,18.8,0.4,37.2-6.2,53.7l0.8,0.3l1.6,0.6
      c6.8-16.9,9.3-35.7,6.3-55C322.9,92.9,289.7,59.3,248.7,50.1L248.7,50.1z"
                  />
                  <path d="M312.8,207.6l-1.4-0.9L312.8,207.6z" />
                  <path
                    className="st7"
                    d="M282.5,137.2l-9.6,2.7c1.3,4.6,2,9.4,2,14.4c0,24.3-16.4,44.7-38.7,50.9l2.7,9.6c26.6-7.3,46-31.7,46.1-60.5
      C284.9,148.4,284.1,142.6,282.5,137.2z"
                  />
                  <path d="M272.9,139.9" />
                  <path
                    className="st8"
                    d="M169.1,154.3h-3.7c0,30.7,24.6,55.7,55.1,56.4l0.1-3.7C192,206.2,169.1,182.9,169.1,154.3L169.1,154.3z"
                  />
                  <path
                    className="st9"
                    d="M173,112.9l7.6,6.5c3.1-3.6,6.7-6.9,10.8-9.7c20.1-13.6,46.3-11.4,63.9,3.7l6.5-7.6
      c-20.9-17.9-52-20.5-75.9-4.4C180.9,104.7,176.6,108.6,173,112.9L173,112.9z"
                  />
                  <path className="st10" d="M281.1,154.3" />
                  <g className="st11">
                    <path
                      className="st12"
                      d="M157.1,157.6c-1-16.6,4.4-32.2,14.1-44.3l8.8,7.1c-8,10-12.5,22.8-11.6,36.5L157.1,157.6L157.1,157.6z
         M168.4,156.9l-3.7,0.2c1.9,30.7,27.9,54.1,58.5,52.8l-0.1-3.7C194.4,207.4,170.1,185.5,168.4,156.9z"
                    />
                  </g>

                  <path d="M191.7,104.1L191,103c-9.7,5.8-17.3,14.2-22.3,24l1.1,0.5C174.8,117.8,181.9,109.8,191.7,104.1z" />

                  <path
                    className="st16"
                    d="M91.3,91.5l3.6,1.8c-15.3,39.5-15.1,81-1.3,116.4c13.9,35.7,40.9,65.2,77.8,79.8
      c37,14.7,76.8,11.8,111.4-4.8c34.2-16.4,63.3-46.1,79.2-85.4l3.3,1.3c-32.8,80.9-119.9,122.2-195.2,92.4
      C94.7,263.1,59.7,172.8,91.3,91.5z"
                  />
                  <path
                    className="st17"
                    d="M249.7,260l-0.8-2.5c-39.3,11.9-83.6-0.6-110.5-34.9c-11.8-15-18.7-32.2-21.1-49.7l-0.9,0.1l-1.7,0.2
      c2.4,18,9.5,35.7,21.6,51C164,259.3,209.4,272.2,249.7,260L249.7,260z"
                  />
                  <g id="Likely" className="st18">
                    <path
                      className="st19"
                      d="M222.1,35.5l-7,0.4l-0.7-12.2l2.5-0.1l0.6,10l4.5-0.3L222.1,35.5L222.1,35.5z M224.3,35.5l0.1-12.2h2.5
        l-0.1,12.2H224.3z M232.6,35.8l-2.5-0.2l1-12.2l2.5,0.2l-0.5,5.4h1.7l2.4-5l2.8,0.2l-3.2,5.9l2.2,6.3l-2.9-0.2l-1.6-5h-1.7
        L232.6,35.8L232.6,35.8z M241.3,36.7l2.3-12l7.8,1.5l-0.4,2.1l-5.3-1l-0.5,2.8l4.3,0.8l-0.4,2.1l-4.3-0.8l-0.6,2.9l5.3,1
        l-0.4,2.1L241.3,36.7L241.3,36.7z M258.3,41l-6.6-2.1l3.6-11.7l2.4,0.7l-3,9.6l4.3,1.3L258.3,41L258.3,41z M263.3,43.2l-2.3-1
        l2-4.5l-0.5-8.2l2.5,1.1l0.1,5.4l4-3.6l2.5,1.1l-6.4,5.2L263.3,43.2L263.3,43.2z"
                    />
                  </g>
                  <g id="unlikely" className="st18">
                    <path
                      className="st19"
                      d="M107.2,197.2c1.3-0.5,1.8-1.4,1.3-2.8s-1.4-1.8-2.7-1.4l-7.7,2.7l-0.8-2.3l7.6-2.7c1.4-0.5,2.6-0.5,3.5,0
        s1.6,1.5,2.1,3s0.6,2.7,0.2,3.7s-1.3,1.7-2.7,2.2l-7.6,2.7l-0.8-2.3L107.2,197.2L107.2,197.2z M108,186.5l-12,2.2l-0.8-4.1
        l9.4-4.7v-0.2l-9.9,1.8l-0.5-2.4l12-2.2l0.7,4l-9.4,4.8v0.2l9.9-1.8L108,186.5L108,186.5z M105.5,166.7l0.5,6.9l-12.2,0.9
        l-0.2-2.5l10-0.7l-0.3-4.5L105.5,166.7L105.5,166.7z M105.5,164.4l-12.2,0.1V162l12.2-0.1V164.4z M105.6,156.1l-0.2,2.5
        l-12.2-0.8l0.2-2.5l5.4,0.4l-0.1-1.7l-5.1-2.4l0.2-2.8l5.9,3.1l6.3-2.3l-0.2,2.9l-5,1.7l0.1,1.7L105.6,156.1L105.6,156.1z
         M106.4,147.3l-12.1-1.8l1.2-7.8l2.1,0.3l-0.8,5.4l2.8,0.4l0.7-4.3l2.1,0.3l-0.7,4.3l2.9,0.4l0.8-5.4l2.1,0.3L106.4,147.3
        L106.4,147.3z M109.6,130l-1.5,6.8l-11.9-2.7l0.5-2.4l9.8,2.2l1-4.4L109.6,130z M111.2,124.5l-0.7,2.4l-4.8-1.3l-8,1.7l0.7-2.7
        l5.3-0.8l-4.1-3.5l0.7-2.7l6,5.6L111.2,124.5L111.2,124.5z"
                    />
                  </g>
                </g>
                <g id="icons">
                  <path
                    className="st7"
                    d="M7.6,71.7c-2-0.6-4.3,1.2-5.1,3.9c0,0.1-0.1,0.3-0.1,0.4C2,75.6,1.6,75.3,1,75.1c-2.2-0.7-4.9,1.3-5.9,4.5
      c-0.5,1.7-0.5,3.4,0,4.7c-1.5,0.7-2.8,2.2-3.4,4.3c-0.9,3,0,6,2,6.9c-0.1,0.3-0.2,0.5-0.3,0.8c-0.7,2.3,0,4.6,1.7,5.1
      c0.3,0.1,0.6,0.1,0.9,0.1c0.8-0.1,1.6-0.6,2.3-1.4c0.3,0.6,0.8,1.1,1.2,1.6c0.5,0.4,1,0.8,1.7,0.9c3.3,1,7.1-1.9,8.6-6.6
      c0.2-0.6,0.3-1.2,0.4-1.8c0.7-0.1,1.4-0.2,2.2-0.1c-2.6,9.9-1.4,10.3-0.9,10.4c0.6,0.2,1.7,0.5,4.9-9.5c1.9,0.8,3.9,2.2,5.7,4.7
      l3.4-11.8c-2.8,1.1-5.3,1.3-7.3,1c2.6-10.2,1.5-10.5,0.9-10.6c-0.5-0.2-1.7-0.5-4.8,9.2c-1.2-0.7-2.2-1.6-2.9-2.3
      c0.1-0.2,0.1-0.3,0.2-0.5c0.7-2.3,0-4.6-1.7-5.2c-0.4-0.1-0.8-0.1-1.3,0C9,79,9.3,78.3,9.5,77.6C10.4,75,9.5,72.3,7.6,71.7
      L7.6,71.7z M12.2,102.4c0.1-1.3,0.5-3.7,1.4-7.4c0.4,0.1,0.9,0.2,1.3,0.3C13.8,99,12.8,101.2,12.2,102.4z M15.8,87.6
      c1.1-3.5,2.1-5.8,2.7-6.9c-0.1,1.3-0.5,3.8-1.4,7.4C16.6,88,16.2,87.8,15.8,87.6z"
                  />
                  <path
                    className="st7"
                    d="M-8.2,175.8c-0.3,5.3,3.7,9.9,9.1,10.3c5.3,0.3,9.9-3.7,10.3-9.1c0.3-5.3-3.7-9.9-9.1-10.3
      C-3.3,166.4-7.9,170.4-8.2,175.8z M2.5,159.9l-3.1,5.3l5.4,0.3C4.9,165.5,2.5,159.9,2.5,159.9L2.5,159.9z M13.8,165.4L7.9,167
      l3.6,4.1L13.8,165.4z M-3.8,166.3l-5.6-2.3l1.6,5.9C-7.9,169.9-3.8,166.3-3.8,166.3z M-9.4,172.9l-5.6,2.4l5.3,3.1
      C-9.7,178.3-9.4,172.9-9.4,172.9z M12.2,179.8l5.6-2.4l-5.3-3.1L12.2,179.8z M0.4,192.8l3.1-5.3l-5.4-0.3L0.4,192.8L0.4,192.8z
       M6.7,186.4l5.6,2.3l-1.6-5.9C10.8,182.8,6.7,186.4,6.7,186.4z M-10.9,187.3l5.9-1.6l-3.6-4.1L-10.9,187.3z"
                  />
                  <path
                    className="st7"
                    d="M36.4,245l-14.8-5.3c0,0,0.3,6.8,5.3,8.6C31.8,250.1,36.4,245,36.4,245L36.4,245z M9,257l2.7-7.5l-0.9-0.3
      l-2.7,7.5L9,257z M5.9,256.9l8.5,3c1,0.4,2.2-0.2,2.6-1.2l0.3-0.7l2.1,0.7l-1.3,3.7c-0.4,1,0.3,2.2,1.3,2.6l4.6,1.6
      c1,0.4,2.2-0.2,2.6-1.2l1.2-3.3l1.8,0.6v0.1c-0.4,1,0.2,2.2,1.2,2.6l8.5,3c1,0.4,2.2-0.2,2.6-1.2l2-5.7c0.4-1-0.2-2.2-1.2-2.6
      l-8.5-3c-1-0.4-2.2,0.2-2.6,1.2l-0.7,1.9l-1.8-0.7l1.2-3.4c0.4-1-1.8-4.8-1.8-4.8l-2.3-0.8c0,0-4.1,1.6-4.4,2.6l-1.1,2.9l-2.1-0.7
      l0.4-1.2c0.4-1-0.2-2.2-1.2-2.6l-8.5-3c-1-0.4-2.2,0.2-2.6,1.2l-2,5.6C4.3,255.4,4.8,256.6,5.9,256.9z M42.7,261.4l-2,5.7
      c-0.2,0.5-0.8,0.8-1.3,0.6l2.7-7.5C42.6,260.3,42.9,260.8,42.7,261.4L42.7,261.4z M41.1,259.7l-2.7,7.5l-0.9-0.3l2.7-7.5
      L41.1,259.7z M39.2,259.1l-2.7,7.5l-0.9-0.3l2.7-7.5C38.3,258.7,39.2,259.1,39.2,259.1z M37.4,258.4l-2.7,7.5l-0.9-0.3l2.7-7.5
      L37.4,258.4z M35.5,257.7l-2.7,7.5l-0.9-0.3l2.7-7.5C34.5,257.4,35.5,257.7,35.5,257.7z M32.3,257.6c0.2-0.5,0.8-0.8,1.3-0.6
      l-2.7,7.5c-0.5-0.2-0.8-0.8-0.6-1.3L32.3,257.6z M27.5,254.1l0.7,0.3l-3.5,9.7l-0.7-0.3L27.5,254.1z M18,252.8l-2,5.7
      c-0.2,0.5-0.8,0.8-1.3,0.6l2.7-7.5C17.9,251.7,18.1,252.2,18,252.8L18,252.8z M5.6,254.7l2-5.6c0.2-0.5,0.8-0.8,1.3-0.6l5.7,2
      l-2.7,7.5l0.9,0.3l2.7-7.5l0.9,0.3l-2.7,7.5l-7.5-2.7C5.7,255.8,5.4,255.2,5.6,254.7L5.6,254.7z M7.1,256.3l2.7-7.5l-0.9-0.3
      L6.2,256L7.1,256.3z M10.9,257.7l2.7-7.5l-0.9-0.3l-2.7,7.5L10.9,257.7z"
                  />
                  <path
                    className="st7"
                    d="M83.9,307.3c-0.5,1.9-1.9,8.5-2.5,11.2c2.3,0,4.7,0.2,6.8,0.6c1.7-1.5,5-4.2,5.9-4.4c1.4-0.2,1.6,0.1,1.1,1.3
      c-0.6,1.2-2.4,5.1-2.4,5.1s1.9,3.8,2.6,4.9c0.6,1.1,0.4,1.5-1,1.3c-0.8-0.1-4.2-2.6-6-4c-2.1,0.5-4.5,0.9-6.8,1
      c0.7,2.7,2.4,9.1,3,11c0.5,1.7-0.9,1.1-0.9,1.1c-0.6-0.3-1.9-1-2.3-1.5c-2.9-3.5-7.2-9-8.2-10.3c-0.7,0-1.5,0-2.4,0.1
      C61,325,60,322.8,60,322.2c0-0.7,0.9-2.9,10.7-3.2c0.9,0,1.7-0.1,2.4-0.1c1-1.4,5.1-7.2,7.8-10.8c0.4-0.6,1.6-1.4,2.3-1.7
      C83,306.3,84.4,305.6,83.9,307.3L83.9,307.3z"
                  />
                  <g>
                    <path
                      className="st7"
                      d="M149.9,358.6c0.3,0.1,0.5,0,0.6-0.1s0.3-0.4,0.3-0.7l-1.5-0.3c-0.1,0.4-0.1,0.6,0,0.8
        S149.6,358.6,149.9,358.6L149.9,358.6z"
                    />
                    <path
                      className="st7"
                      d="M144.3,360.6l4.5,15.7l10.9-12.2c1.1-1.1,1.9-2.6,2.3-4.2c1.1-4.9-2-9.9-6.9-11s-9.9,2-11,6.9
        C143.7,357.4,143.8,359,144.3,360.6z M152.4,360.6l2.6,0.5l0.4-1.8l-2.2-0.4l0.2-1.1l2.2,0.4l0.4-2.1l1.2,0.2l-1.2,6.1l-3.9-0.8
        L152.4,360.6L152.4,360.6z M148.4,356.4l2.6,0.5c0-0.3,0-0.5-0.1-0.6c-0.1-0.2-0.3-0.3-0.6-0.3c-0.6-0.1-1.1-0.2-1.5-0.2h-0.3
        l0.2-0.9c0.7,0,1.4,0,1.9,0.1c0.7,0.1,1.1,0.4,1.4,0.8c0.2,0.4,0.3,1,0.1,1.8c-0.3,1.6-1.1,2.3-2.5,2c-1.3-0.3-1.8-1.1-1.5-2.4
        L148.4,356.4L148.4,356.4z"
                    />
                  </g>
                  <path
                    className="st7"
                    d="M225.1,359.7c0,0-5.5,0.4-5.8,5.9c0.1,5.9,5.9,6.6,5.9,6.6s0.1,8.8,8.1,9.1c6.9,0.1,8.5-6.4,8.5-6.4
      s2.2,1.8,4.9,0c2.4-1.8,1.7-4.5,1.7-4.5s5.5-1.5,5-7.2c-0.6-5.6-6.5-5.2-6.5-5.2L225.1,359.7L225.1,359.7z"
                  />
                  <path
                    className="st7"
                    d="M318.1,349.5c3.4,0.8,5.9,3.7,6,3.9c0.4,0.2,0.6,0.2,0.6,0.2l4.4-2.6l2.2,3.7l1.1-0.7l-2.2-3.7l1.1-0.7
      l1.2,1.9l0.8-0.5l-1.2-1.9l13.8-8.2c0.4-0.2,0.5-0.7,0.3-1l-0.7-6.8l8.9-5.3c-4.7,1.8-16.9-3.8-16.9-3.8l-24.8,14.7
      c1.3,3-4.1,16.3-4.1,16.3L318.1,349.5L318.1,349.5z M326.8,350c0.2,0.4,0.1,0.8-0.3,1l-1.7,1c-0.4,0.2-0.8,0.1-1-0.3l-1.6-2.7
      c-0.2-0.4-0.1-0.8,0.3-1l1.7-1c0.4-0.2,0.8-0.1,1,0.3L326.8,350z M335.3,344.9c0.2,0.4,0.1,0.8-0.3,1l-2.3,1.4
      c-0.4,0.2-0.8,0.1-1-0.3l-1.6-2.7c-0.2-0.4-0.1-0.8,0.3-1l2.3-1.4c0.4-0.2,0.8-0.1,1,0.3L335.3,344.9z M339.6,342.4
      c0.2,0.4,0.1,0.8-0.3,1l-2.3,1.4c-0.4,0.2-0.8,0.1-1-0.3l-1.6-2.7c-0.2-0.4-0.1-0.8,0.3-1l2.3-1.4c0.4-0.2,0.8-0.1,1,0.3
      L339.6,342.4z M343.8,339.9c0.2,0.4,0.1,0.8-0.3,1l-2.3,1.4c-0.4,0.2-0.8,0.1-1-0.3l-1.6-2.7c-0.2-0.4-0.1-0.8,0.3-1l2.3-1.4
      c0.4-0.2,0.8-0.1,1,0.3L343.8,339.9z"
                  />
                  <g>
                    <path
                      className="st7"
                      d="M422.3,278l-3.5-13c0.6-1,1-1.6,1-1.6l-7.1-3.5l-16.3,16.8l7.9,9.2c0,0,0.2,1.8,2.7-2.1l-0.3,0.5
        c0,0,11.4-2.4,13.4-2.8C422.2,280.9,422.3,278,422.3,278L422.3,278z M405.5,282.9l-6.3-6.8l2.1-2.2l7,4.4L405.5,282.9
        L405.5,282.9z M409.6,276.5l-6.7-4.2l2.5-2.4l5.9,3.7L409.6,276.5L409.6,276.5z M412.4,272.1l-5.4-3.4l2.2-2.1l4.8,3L412.4,272.1
        z M414.7,268.4l-4.5-2.9l1.7-1.6l4,2.5L414.7,268.4z M416.5,265.5l-3.7-2.3l1.4-1.3l3.3,2.1L416.5,265.5L416.5,265.5z"
                    />
                    <path
                      className="st7"
                      d="M404.5,268.2c0.4-0.3,0.7-0.7,1-1.1c1.5-2.3,0.8-5.4-1.5-6.8c-2.3-1.5-5.4-0.8-6.8,1.5
        c-1.5,2.3-0.8,5.4,1.5,6.8c0.3,0.2,0.5,0.3,0.8,0.4l-0.7,0.1l-4.1,6.5c-0.1-0.1-0.2-0.1-0.3-0.2c-2.3-1.5-5.4-0.8-6.8,1.5
        c-1.5,2.3-0.8,5.4,1.5,6.8c0.1,0.1,0.2,0.1,0.3,0.2l-1.2,1.9l0.1,1.4l0.4,0.3l0.6-0.9l6,3.8l1-1.6c-0.2-0.1-0.3-0.2-0.5-0.4
        l1.5-2.4l3.4,2.2l-1.5,2c-0.1,0.2,0,0.5,0.4,0.5c0,0,0.6,0.5,1.8-1.3c1.1-1.8,2.6-4.1,2.6-4.1l-7.5-8.7l8.3-8.6L404.5,268.2
        L404.5,268.2z M399.9,283.1c0,0,1.3,0.8,1.9,1.2c0.6,0.4,0.3,1.2,0.3,1.2s-0.2,0.6-0.7,1.1c-0.4,0.5-1,0.2-1,0.2l-2-1.2
        L399.9,283.1L399.9,283.1z M394.3,281.2c-0.8,1.3-2.5,1.7-3.8,1.1c-0.1,0-0.2-0.1-0.3-0.2c-1.4-0.9-1.8-2.7-0.9-4.2
        c0.9-1.4,2.7-1.8,4.2-0.9c0.1,0.1,0.2,0.1,0.3,0.2C394.8,278.2,395.1,279.9,394.3,281.2L394.3,281.2z M403.8,266
        c-0.9,1.4-2.7,1.8-4.2,0.9c-1.4-0.9-1.8-2.7-0.9-4.2c0.9-1.4,2.7-1.8,4.2-0.9C404.3,262.8,404.7,264.6,403.8,266z"
                    />
                  </g>
                  <g>
                    <path
                      className="st7"
                      d="M478.9,210.6c0-0.3-0.1-0.5-0.4-0.6c-0.3,0-0.5,0.1-0.6,0.3c-7.8-1.8-12.8-3.8-12.8-3.8l-0.5,2.6
        c0,0,5.4,0,13.3,1.3c0,0.2,0.1,0.5,0.4,0.5C478.6,211.1,478.8,210.9,478.9,210.6c0.5,0.1,1,0.2,1.6,0.3
        C480,210.8,479.4,210.7,478.9,210.6z"
                    />
                    <path
                      className="st7"
                      d="M463.5,206.2c0,0-10.9-0.9-32.6-7.7c0,0-4.3-1.7-6.4-4.9l-0.7,3.5c9.9,4.8-1,5.2-1,5.2l-0.7,3.5
        c3.1-2.2,7.7-2.1,7.7-2.1c22.6,1.9,33.1,5.2,33.1,5.2L463.5,206.2z"
                    />
                  </g>
                  <g>
                    <path
                      className="st7"
                      d="M436.7,127.2l-8.6,1.1c-0.6-4.2-1.3-8.4-2.5-12.5l8.4-2.2c2.7,1.7,5.2,3.5,7.8,5.5
        C440.1,121.9,438.4,124.6,436.7,127.2L436.7,127.2z M433.8,122.3c-0.1-1.1-0.4-2.1-0.6-3.1l-3,0.6c0.2,1,0.5,2,0.6,3.1
        L433.8,122.3z"
                    />
                    <path
                      className="st7"
                      d="M455.5,141.9l-25.6,0.4c-0.4,0-0.8-0.3-0.8-0.7l-0.1-1.8h0.7c3.3-0.1,5.9-2.1,5.7-4.5
        c-0.2-2.4-3.1-3.9-6.3-3.5l-0.7,0.1l-0.2-1.8c-0.1-0.4,0.3-0.8,0.7-0.8l25.3-3.9c0.4-0.1,0.8,0.3,0.9,0.8
        c0.7,4.9,1.1,9.8,1.2,14.7C456.3,141.4,456,141.9,455.5,141.9L455.5,141.9z M445.6,131.6c0.4,0,0.7-0.5,0.7-0.9l-0.2-1.4
        c-0.1-0.5-0.5-0.8-0.9-0.7l-4.4,0.6c-0.4,0.1-0.7,0.5-0.7,0.9l0.2,1.3c0.1,0.4,0.5,0.8,0.9,0.7L445.6,131.6z M452.5,130.9
        c0.4,0,0.7-0.5,0.7-1l-0.2-1.5c-0.1-0.5-0.5-0.8-0.9-0.8l-2.8,0.4c-0.4,0.1-0.7,0.5-0.7,1l0.2,1.4c0.1,0.5,0.5,0.8,0.9,0.8
        L452.5,130.9z M446,135.9c0.4,0,0.8-0.4,0.7-0.9l-0.1-1.4c0-0.5-0.4-0.8-0.9-0.8l-4.4,0.4c-0.4,0-0.8,0.4-0.7,0.9l0.1,1.3
        c0,0.4,0.4,0.8,0.9,0.8L446,135.9z M452.9,135.5c0.4,0,0.8-0.5,0.7-1l-0.1-1.5c0-0.5-0.4-0.9-0.9-0.8l-2.8,0.3
        c-0.4,0-0.8,0.5-0.7,0.9l0.1,1.4c0,0.5,0.4,0.8,0.9,0.8L452.9,135.5L452.9,135.5z M446.2,140.3c0.4,0,0.8-0.4,0.8-0.9V138
        c0-0.5-0.4-0.8-0.8-0.8l-4.5,0.3c-0.4,0-0.8,0.4-0.8,0.9v1.3c0,0.4,0.4,0.8,0.8,0.8L446.2,140.3L446.2,140.3z M453.1,140.1
        c0.4,0,0.8-0.4,0.8-0.9v-1.5c0-0.5-0.4-0.9-0.8-0.9l-2.8,0.2c-0.4,0-0.8,0.4-0.8,0.9v1.4c0,0.5,0.4,0.9,0.8,0.8H453.1z
         M457.5,141.8c-0.1-5.6-0.6-11.1-1.4-16.6c3.1,2.3,6.4,4.8,9.3,7.6c-1.2,1.5-2.4,3-3.7,4.5l1.1-0.1c0.4,0,0.8,0.4,0.8,0.9v0.5
        c0,0.5-0.3,1-0.8,1l-3.5,0.1L457.5,141.8z"
                    />
                  </g>
                  <path
                    className="st7"
                    d="M425.9,32.7c1.9-0.3,3.3-0.6,3.7-1v-0.1l-0.1-0.6v-0.1l-0.8-0.7l-0.7-0.6l-0.7-0.2c-2.4-1-7.6-2.1-13.8,3.2
      l0.3,1c0,0,4.3-0.2,8.4-0.5c1.2,3.7,2.2,7.5,2.9,11.2l-0.2-0.3h-0.1l-0.8,0.1l-0.3-0.4h-0.2l-0.4,0.3v0.1l0.1,0.1
      c-3.4,1-6.7,2-10.1,3.2l-0.7-0.9h-0.1l-0.3,0.2v0.1l0.1,0.1l-0.7,0.5v0.1l0.3,0.4c-3.6,1.3-7.1,2.7-10.5,4.2l0.6,0.9
      c3.5-1.4,7-2.8,10.6-4.1c1.1,1.7,2.3,3.4,3.3,5.2c-2.5,2.7-5.1,5.3-7.8,7.8l0.6,0.8c2.7-2.5,5.3-5,7.8-7.6l0.2,0.4h0.1l0.7-0.4
      l0.1,0.1h0.1l0.4-0.2v-0.1l-0.6-1c2.4-2.5,4.8-5.2,7.1-7.9v0.1h0.1l0.5-0.3v-0.1l-0.3-0.4l0.4-0.6c0.7,3.6,1.2,7.3,1.5,10.8
      l0.1,1.1v0.1L426,56l0.7,0.8l0.1,1l0.1,0.1l3.4,0.2h0.1V58C430,49.7,428.6,41.2,425.9,32.7L425.9,32.7z M416.9,52.9
      c-0.9-1.6-2-3-2.9-4.5c3.1-1,6.2-2,9.4-2.9C421.3,48,419.1,50.5,416.9,52.9z"
                  />
                  <path
                    className="st7"
                    d="M353.7-7.2c1.6-4.6,4.2-5.6,7.1-6.2c3.1-0.6,5.3-2.1,5.3-2.1s-0.3,2.3-2.7,3.9c3.7-0.2,7.2-3,8.1-4.4
      c1.2,6.8-3.2,14.5-9.9,16.7c19.1,5.3,20.3-14.2,19.9-16.9c-0.6,1.7-2.5,3.8-4.5,3.6c5.1-8.7,2-14.6,2-14.6c-3,4.1-9.4,5.5-14.7,6
      c1.3-1.5,2.2-2.6,2.8-4.7c-2.8,2.4-6.8,2.5-9.9,4.6C353-18.6,351.7-14.8,353.7-7.2L353.7-7.2z"
                  />
                  <path
                    className="st7"
                    d="M320.6-60.3c-2.4-2.7-6.5-4.8-10.9-5.6c-5.5-1.1-10.3,0-13.3,2.9c-1.4,1.4-2.3,3.1-2.8,5.2
      c-0.4,1.9-0.5,4-0.2,6.3c3.8-6.8,11.6-11.1,19.4-9.6c0,0-7.6,0.5-13.3,5.6l-0.2,0.2c-1.2,1.1-2.2,2.3-3.1,3.8
      c-1.5,2.2-3,5.3-3.8,9.3l3.3,0.6c0,0,0.1-3.2,1.6-6.6c1.4,0.5,2.6,0.8,3.8,1c3,0.6,5.2,0.3,7.1-0.8c1.7-1,2.7-2.5,3.9-4.1
      c1.8-2.5,3.8-5.3,8.4-6.8c0.3-0.1,0.5-0.3,0.5-0.6C320.9-59.8,320.8-60.1,320.6-60.3L320.6-60.3z"
                  />
                  <path
                    className="st7"
                    d="M238.7-70.7c-0.5-5.2-4.3-3.8-4.3-3.8c-3.1-4.1-7.2-0.3-7.2-0.3c-1.5-1.1-2.9,0-3.5,0.7
      c-0.1-2.1-1.7-4.3-1.7-4.3c-0.3-4.2-3.6-7-3.6-7c-0.2-2.8-2.4-4.3-2.4-4.3s-2.2,1.5-2.4,4.3c0,0-3.3,2.8-3.6,7c0,0-3.3,4.9,0,7.5
      c0,0-2.4,2.8,0.7,4.6c0,0,0,2.8,3.7,2.6c0,0.8,0.1,6.5-1.7,10.3h6.7c-1.4-4.4-1.6-9.6-1.7-10.3c3.5,0.1,2.7-2.6,2.7-2.6
      c3.1-1.8,1.6-4.6,1.6-4.6c0.2-0.1,0.4-0.3,0.6-0.4c0.3,1.3,1.7,1.8,1.7,1.8c-4.3,5.5,1,7,1,7c0,2.6,3.7,1.1,3.7,1.1s0.1,0,0.3-0.1
      c-0.2,5.2-0.6,8.1-0.6,8.1h3.7c0,0-0.5-2.9-0.9-8.1c0.2,0,0.4,0.1,0.5,0.2c3.4,1.6,4.3-1.2,4.3-1.2c1.7-1.3-1.3-4.7-1.3-4.7
      C238.8-68,238.7-70.7,238.7-70.7L238.7-70.7z"
                  />
                </g>
              </g>
              <ellipse
                id="THIS"
                className="st20"
                cx="222.2"
                cy="154"
                rx="1.8"
                ry="1.6"
              />
            </svg>

            <g className="money-lg money" id="money" fill="#23262C">
              <path d="M639.6 360.3h1.2v10.4h-1.2z" />
              <path d="M640.3 373.3c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6-.1.9-.8 1.6-1.6 1.6zM658.7 291.7h-1.2v68.1h-29.3v-5.1H627v6h31.7v-69zM595.6 354.7h.8v5.1h19.7v-5.1h.8v6h-21.3v-6z" />
              <path d="M605.4 360.3h1.2v10.4h-1.2z" />
              <circle cx="606" cy="371.7" r="1.6" />
              <g>
                <path d="M554.3 291.7h1.2v68.1h29.3v-5.1h1.2v6h-31.7v-69z" />
                <path d="M572 360.3h1.2v10.4H572z" />
                <path d="M572.7 373.3c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6-.1.9-.8 1.6-1.6 1.6z" />
              </g>
              <g className="one">
                <path d="M576.6 333.7h-5.4v-1.6h5.4c.4 0 .8-.3.8-.8 0-.4-.3-.8-.8-.8h-2.3v-.8c0-.4-.3-.8-.8-.8-.4 0-.8.3-.8.8v.8h-2.3c-.4 0-.8.3-.8.8v3.1c0 .4.3.8.8.8h5.4v1.6h-5.4c-.4 0-.8.3-.8.8s.3.8.8.8h2.3v.8c0 .4.3.8.8.8.4 0 .8-.3.8-.8v-.8h2.3c.4 0 .8-.3.8-.8v-3.1c0-.4-.3-.8-.8-.8z" />
                <path d="M583.2 329.3c-1.1-1.9-2.5-3.4-3.7-4.7-.8-.9-1.7-1.9-2-2.5.5-.3.9-.6 1.2-1 .3-.3.3-.8 0-1.1-.3-.3-.7-.3-1-.1.3-1 1-2.3 2.4-3.4.3-.2.4-.6.2-1 0-.1-1-2-3-2-1.3 0-1.7.8-1.9 1.2-.2.3-.2.4-.5.4-.6 0-1.3-.3-2-.7-.8-.4-1.7-.9-2.7-.9-1.8 0-3.5 1.6-3.6 1.8-.2.2-.2.4-.2.6s.1.4.3.6c1.1.9 2 2 2.4 3.3v.1c-.3-.2-.7-.2-1 .1-.3.3-.3.8 0 1.1.3.3.7.7 1.2 1-.3.7-1.2 1.6-2 2.5-1.1 1.3-2.6 2.8-3.7 4.7-1.3 2.2-2 4.4-2 6.8 0 3.2 1.2 5.5 3.5 6.9 1.9 1.1 4.5 1.7 8.2 1.7 3.7 0 6.3-.5 8.2-1.7 2.3-1.4 3.5-3.7 3.5-6.9.2-2.4-.5-4.6-1.8-6.8zm-14.7-13.4c.5-.4 1.3-.8 1.9-.8.6 0 1.3.3 2 .7.8.4 1.7.9 2.7.9 1.3 0 1.7-.8 1.9-1.2.2-.3.2-.4.5-.4.6 0 1 .3 1.3.6-2.1 1.9-2.7 4.3-2.8 5.3-.6.2-1.3.2-2.4.2s-1.8-.1-2.4-.2c-.2-1-.7-3.2-2.7-5.1zm5 27.2c-7.1 0-10.1-2.1-10.1-7 0-4.6 3-8 5.2-10.4 1.1-1.2 1.9-2.1 2.3-3 .7.2 1.5.2 2.6.2s1.9-.1 2.6-.2c.4.9 1.2 1.8 2.3 3 2.2 2.4 5.2 5.8 5.2 10.4 0 4.9-3 7-10.1 7z" />
              </g>
              <g className="two">
                <path d="M610.1 333.7h-5.4v-1.6h5.4c.4 0 .8-.3.8-.8 0-.4-.3-.8-.8-.8h-2.3v-.8c0-.4-.3-.8-.8-.8-.4 0-.8.3-.8.8v.8h-2.3c-.4 0-.8.3-.8.8v3.1c0 .4.3.8.8.8h5.4v1.6h-5.4c-.4 0-.8.3-.8.8s.3.8.8.8h2.3v.8c0 .4.3.8.8.8.4 0 .8-.3.8-.8v-.8h2.3c.4 0 .8-.3.8-.8v-3.1c-.1-.4-.4-.8-.8-.8z" />
                <path d="M616.6 329.3c-1.1-1.9-2.5-3.4-3.7-4.7-.8-.9-1.7-1.9-2-2.5.5-.3.9-.6 1.2-1 .3-.3.3-.8 0-1.1-.3-.3-.7-.3-1-.1.3-1 1-2.3 2.4-3.4.3-.2.4-.6.2-1 0-.1-1-2-3-2-1.3 0-1.7.8-1.9 1.2-.2.3-.2.4-.5.4-.6 0-1.3-.3-2-.7-.8-.4-1.7-.9-2.7-.9-1.8 0-3.5 1.6-3.6 1.8-.2.2-.2.4-.2.6 0 .2.1.4.3.6 1.1.9 2 2 2.4 3.3v.1c-.3-.2-.7-.2-1 .1-.3.3-.3.8 0 1.1.3.3.7.7 1.2 1-.3.7-1.2 1.6-2 2.5-1.1 1.3-2.6 2.8-3.7 4.7-1.3 2.2-2 4.4-2 6.8 0 3.2 1.2 5.5 3.5 6.9 1.9 1.1 4.5 1.7 8.2 1.7 3.7 0 6.3-.5 8.2-1.7 2.3-1.4 3.5-3.7 3.5-6.9.2-2.4-.4-4.6-1.8-6.8zM602 315.9c.5-.4 1.3-.8 1.9-.8.6 0 1.3.3 2 .7.8.4 1.7.9 2.7.9 1.3 0 1.7-.8 1.9-1.2.2-.3.2-.4.5-.4.6 0 1 .3 1.3.6-2.1 1.9-2.7 4.3-2.8 5.3-.6.2-1.3.2-2.4.2s-1.8-.1-2.4-.2c-.2-1-.7-3.2-2.7-5.1zm5 27.2c-7.1 0-10.1-2.1-10.1-7 0-4.6 3-8 5.2-10.4 1.1-1.2 1.9-2.1 2.3-3 .7.2 1.5.2 2.6.2s1.9-.1 2.6-.2c.4.9 1.2 1.8 2.3 3 2.2 2.4 5.2 5.8 5.2 10.4 0 4.9-3.1 7-10.1 7z" />
              </g>
              <g className="three">
                <path d="M643.9 333.7h-5.4v-1.6h5.4c.4 0 .8-.3.8-.8s-.3-.8-.8-.8h-2.3v-.8c0-.4-.3-.8-.8-.8-.4 0-.8.3-.8.8v.8h-2.3c-.4 0-.8.3-.8.8v3.1c0 .4.3.8.8.8h5.4v1.6h-5.4c-.4 0-.8.3-.8.8s.3.8.8.8h2.3v.8c0 .4.3.8.8.8.4 0 .8-.3.8-.8v-.8h2.3c.4 0 .8-.3.8-.8v-3.1c-.1-.4-.4-.8-.8-.8z" />
                <path d="M650.4 329.3c-1.1-1.9-2.5-3.4-3.7-4.7-.8-.9-1.7-1.9-2-2.5.5-.3.9-.6 1.2-1 .3-.3.3-.8 0-1.1-.3-.3-.7-.3-1-.1.3-1 1-2.3 2.4-3.4.3-.2.4-.6.2-1 0-.1-1-2-3-2-1.3 0-1.7.8-1.9 1.2-.2.3-.2.4-.5.4-.6 0-1.3-.3-2-.7-.8-.4-1.7-.9-2.7-.9-1.8 0-3.5 1.6-3.6 1.8-.2.2-.2.4-.2.6 0 .2.1.4.3.6 1.1.9 2 2 2.4 3.3v.1c-.3-.2-.7-.2-1 .1-.3.3-.3.8 0 1.1.3.3.7.7 1.2 1-.3.7-1.2 1.6-2 2.5-1.1 1.3-2.6 2.8-3.7 4.7-1.3 2.2-2 4.4-2 6.8 0 3.2 1.2 5.5 3.5 6.9 1.9 1.1 4.5 1.7 8.2 1.7 3.7 0 6.3-.5 8.2-1.7 2.3-1.4 3.5-3.7 3.5-6.9.2-2.4-.4-4.6-1.8-6.8zm-14.6-13.4c.5-.4 1.3-.8 1.9-.8.6 0 1.3.3 2 .7.8.4 1.7.9 2.7.9 1.3 0 1.7-.8 1.9-1.2.2-.3.2-.4.5-.4.6 0 1 .3 1.3.6-2.1 1.9-2.7 4.3-2.8 5.3-.6.2-1.3.2-2.4.2s-1.8-.1-2.4-.2c-.2-1-.7-3.2-2.7-5.1zm5 27.2c-7.1 0-10.1-2.1-10.1-7 0-4.6 3-8 5.2-10.4 1.1-1.2 1.9-2.1 2.3-3 .7.2 1.5.2 2.6.2s1.9-.1 2.6-.2c.4.9 1.2 1.8 2.3 3 2.2 2.4 5.2 5.8 5.2 10.4 0 4.9-3.1 7-10.1 7z" />
              </g>
            </g>
            <g fill="#414751" className="million">
              <path d="M576.8 441.8h-9.6v-2.7l7.5-2.1-7.5-2.1v-2.7h9.6v1.6H569v.2l7.5 2.3v1.6L569 440v.2h7.9v1.6zM576.8 429.2h-9.6v-1.6h9.6v1.6zM576.8 419.3v5.4h-9.6v-1.6h8.2v-3.8h1.4zM576.8 412v5.4h-9.6v-1.6h8.2V412h1.4zM576.8 410h-9.6v-1.6h9.6v1.6zM575.8 399c.8.6 1.2 1.6 1.2 3s-.4 2.5-1.2 3c-.8.6-2 .9-3.7.9s-2.9-.3-3.8-.9c-.8-.6-1.2-1.6-1.2-3s.4-2.4 1.2-3c.8-.6 2.1-.9 3.8-.9 1.7.1 2.9.4 3.7.9zm-1 4.9c.6-.3.8-.9.8-1.8s-.3-1.5-.8-1.8c-.5-.3-1.4-.5-2.7-.5-1.3 0-2.2.2-2.8.5-.6.3-.9.9-.9 1.8s.3 1.5.9 1.8c.6.3 1.5.5 2.8.5s2.2-.2 2.7-.5zM576.8 395.7h-9.6V393l8.2-2.9v-.2h-8.2v-1.6h9.6v2.7l-8.2 3v.2h8.2v1.5zM568.5 382.6c0 1.2.4 1.8 1.2 1.8.4 0 .7-.1.9-.4.2-.3.4-.9.7-1.9.3-1 .6-1.6 1-2 .4-.4.9-.6 1.7-.6 1 0 1.8.3 2.3.9s.7 1.4.7 2.3c0 .8-.1 1.7-.3 2.6l-.1.5-1.2-.2c.2-1.3.3-2.2.3-2.9 0-1.2-.5-1.7-1.5-1.7-.4 0-.7.1-.9.4-.2.3-.4.8-.6 1.8-.2.9-.5 1.6-.9 2s-1 .7-1.9.7-1.5-.3-2-.8c-.4-.6-.7-1.3-.7-2.3 0-.7.1-1.6.3-2.5l.1-.5 1.3.1c-.4 1.2-.4 2.1-.4 2.7z" />
            </g>
            <g fill="#414751">
              <path d="M600.3 437.8v-3.6c0-1 .2-1.7.6-2.2.4-.5 1-.7 1.9-.7.6 0 1 .1 1.3.3.3.2.6.5.8.9.4-.9 1.1-1.4 2.2-1.4 1.8 0 2.7 1 2.7 3.1v3.8h-9.5zm1.4-3.6v2h2.7v-2.1c0-.9-.5-1.4-1.4-1.4-.9.1-1.3.6-1.3 1.5zm4 0v2.1h2.8v-2.1c0-.5-.1-.9-.3-1.2s-.6-.4-1.1-.4c-.5 0-.9.1-1.1.4s-.3.7-.3 1.2zM609.9 428.5h-9.6V427h9.6v1.5zM609.9 418.7v5.4h-9.6v-1.6h8.2v-3.8h1.4zM609.9 411.3v5.4h-9.6v-1.6h8.2v-3.8h1.4zM609.9 409.4h-9.6v-1.6h9.6v1.6zM608.9 398.4c.8.6 1.2 1.6 1.2 3s-.4 2.5-1.2 3c-.8.6-2 .9-3.7.9s-2.9-.3-3.8-.9c-.8-.6-1.2-1.6-1.2-3s.4-2.4 1.2-3c.8-.6 2.1-.9 3.8-.9 1.7 0 2.9.3 3.7.9zm-1 4.8c.6-.3.8-.9.8-1.8s-.3-1.5-.8-1.8c-.5-.3-1.4-.5-2.7-.5-1.3 0-2.2.2-2.8.5-.6.3-.9.9-.9 1.8s.3 1.5.9 1.8c.6.3 1.5.5 2.8.5s2.1-.2 2.7-.5zM609.9 395h-9.6v-2.7l8.2-2.9v-.2h-8.2v-1.6h9.6v2.7l-8.2 3v.2h8.2v1.5zM601.5 381.9c0 1.2.4 1.8 1.2 1.8.4 0 .7-.1.9-.4.2-.3.4-.9.7-1.9.3-1 .6-1.6 1-2 .4-.4.9-.6 1.7-.6 1 0 1.8.3 2.3.9s.7 1.4.7 2.3c0 .8-.1 1.7-.3 2.6l-.1.5-1.2-.2c.2-1.3.3-2.2.3-2.9 0-1.2-.5-1.7-1.5-1.7-.4 0-.7.1-.9.4-.2.3-.4.8-.6 1.8-.2.9-.5 1.6-.9 2s-1 .7-1.9.7-1.5-.3-2-.8c-.4-.6-.7-1.3-.7-2.3 0-.7.1-1.6.3-2.5l.1-.5 1.3.1c-.3 1.2-.4 2.1-.4 2.7z" />
            </g>
            <g fill="#414751">
              <path d="M637.2 446.5h-1.4v-7h1.4v2.7h8.2v1.6h-8.2v2.7zM642 435.9h3.4v1.6h-9.6v-3.6c0-2.2 1-3.2 3.1-3.2 1.4 0 2.3.5 2.8 1.6l3.8-1.6v1.7l-3.4 1.4v2.1zm-3.1-3.7c-1.1 0-1.7.6-1.7 1.7v2.1h3.5v-2.1c0-.6-.2-1-.5-1.2s-.8-.5-1.3-.5zM645.4 428.1h-9.6v-1.6h9.6v1.6zM645.4 418.3v5.4h-9.6v-1.6h8.2v-3.8h1.4zM645.4 410.9v5.4h-9.6v-1.6h8.2v-3.8h1.4zM645.4 409h-9.6v-1.6h9.6v1.6zM644.4 398c.8.6 1.2 1.6 1.2 3s-.4 2.5-1.2 3c-.8.6-2 .9-3.7.9s-2.9-.3-3.8-.9c-.8-.6-1.2-1.6-1.2-3s.4-2.4 1.2-3c.8-.6 2.1-.9 3.8-.9 1.7 0 2.9.3 3.7.9zm-1 4.8c.6-.3.8-.9.8-1.8s-.3-1.5-.8-1.8c-.5-.3-1.4-.5-2.7-.5-1.3 0-2.2.2-2.8.5-.6.3-.9.9-.9 1.8s.3 1.5.9 1.8c.6.3 1.5.5 2.8.5s2.1-.1 2.7-.5zM645.4 394.6h-9.6v-2.7l8.2-2.9v-.2h-8.2v-1.6h9.6v2.7l-8.2 3v.2h8.2v1.5zM637 381.5c0 1.2.4 1.8 1.2 1.8.4 0 .7-.1.9-.4.2-.3.4-.9.7-1.9.3-1 .6-1.6 1-2 .4-.4.9-.6 1.7-.6 1 0 1.8.3 2.3.9s.7 1.4.7 2.3c0 .8-.1 1.7-.3 2.6l-.1.5-1.2-.2c.2-1.3.3-2.2.3-2.9 0-1.2-.5-1.7-1.5-1.7-.4 0-.7.1-.9.4-.2.3-.4.8-.6 1.8-.2.9-.5 1.6-.9 2s-1 .7-1.9.7-1.5-.3-2-.8c-.4-.6-.7-1.3-.7-2.3 0-.7.1-1.6.3-2.5l.1-.5 1.3.1c-.3 1.2-.4 2.2-.4 2.7z" />
            </g>
          </svg>
        </div>
      </div>
    );
  }
}
