import React, {Component} from 'react';
import Loadigpage from './loadingpage';
import {BrowserRouter, Route} from 'react-router-dom';
import Homepage from './homepage';
export default class Firstpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }
  componentDidMount() {
    this.id = setTimeout(() => this.setState({redirect: true}), 7000);
  }
  componentWillUnmount() {
    clearTimeout(this.id);
  }
  render() {
    return (
      <BrowserRouter>
        {this.state.redirect ? (
          <Route path="*" component={Homepage} />
        ) : (
          <div>
            <Loadigpage />
          </div>
        )}
      </BrowserRouter>
    );
  }
}
