import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import Reducers from './reducers'


const store=createStore(Reducers);

ReactDOM.render(
    <Provider store={store}>
        <App />
        </Provider>,

document.getElementById('root'));

serviceWorker.unregister();
