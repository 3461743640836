import React from 'react';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import Middlelogo from './middlelogo';
import './Styling/navigation.css';
export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <div className="headerContainer">
        <Navbar className="navigation" light expand="md">
          <NavbarBrand style={{color: '#b6b6b6'}} href="/">
            HOME
          </NavbarBrand>
          <Nav className="navigation-detail">
            <NavItem>
              <NavLink href="/">Blog</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/aboutme">About Me</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/contact">Contact</NavLink>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
    );
  }
}
