import React from 'react';
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom';
import ContactPage from './contact';
import Aboutme from './aboutme';
import Firstpage from './firstpage';

const Main = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Firstpage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/aboutme" component={Aboutme} />
    </Switch>
  </BrowserRouter>
);

export default Main;
