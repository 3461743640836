import React, {Component} from 'react';
import './Styling/loading.css';
import Svganimation from './svganim';
class Loadingpage extends Component {
  render() {
    return (
      <div className="MainContainer">
        <div className="LeftHalf"></div>
        <div className="overlay">
          <Svganimation />
        </div>
        <div className="RightHalf"></div>
      </div>
    );
  }
  s;
}

export default Loadingpage;
