import React, {Component} from 'react';

import './Styling/aboutme.css';
import NavBar from './navbar';
import MyLogo from './mylogo';
import ProfilePic from '../images/profile.jpg';

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      comment: '',
    };
  }
  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };
  handleSubmit = event => {
    alert('A form was submitted: ' + this.state.name);

    fetch('http://rawalritesh.com.no/api/contact', {
      method: 'POST',
      body: JSON.stringify(this.state),
    }).then(function (response) {
      console.log(response);
      return response.json();
    });

    event.preventDefault();
  };

  render() {
    return (
      <div className="AboutMeWithHeade">
        <div>
          <NavBar />
        </div>
        <div className="AboutMainContainer">
          <div>
            <MyLogo />
          </div>

          <div className="AboutLeftHalf">
            <div className="AboutLeftFirst">
              <img src={ProfilePic} alt="" className="ImageCover"></img>
            </div>
            <div className="AboutLeftLast">
              <div className="AboutRightTop">
                <h1>ABOUT ME</h1>
                <div className="Heading">
                  <p>
                    I find great interest in all new digital technologies and
                    latest design practices. I work with a strong technical team
                    so we can take your idea and turn it into a successful
                    product that users will enjoy. Together we form
                    Youandigraphics.
                  </p>
                </div>
              </div>

              <div className="IntroFirst"></div>
            </div>
          </div>
          <div className="AboutRightHalf">
            <div className="IntroDevelope"></div>
            <div className="InputContent">
              <div className="form-group">
                <label className="InputName">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  value={this.state.value}
                  name="name"
                  onChange={this.handleChange}
                  placeholder="Name"></input>
              </div>
              <div className="form-group">
                <label className="InputName">Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={this.state.value}
                  name="email"
                  onChange={this.handleChange}
                  id="exampleFormControlInput1"
                  placeholder="E-mail"></input>
              </div>
              <div className="form-group">
                <label className="InputName">Website</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Website"></input>
              </div>
              <div className="form-group">
                <label className="InputName">Message</label>
                <textarea
                  className="form-control"
                  value={this.state.value}
                  name="comment"
                  onChange={this.handleChange}
                  id="exampleFormControlTextarea1"
                  rows="3"></textarea>
              </div>
              <div className="ButtonDiv">
                <button
                  type="submit"
                  onClick={this.handleSubmit}
                  className="btn btn-primary mb-2">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPage;
